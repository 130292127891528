export const config = {
    RPC_URL: 'https://rpc.comdex.one',
    REST_URL: 'https://rest.comdex.one',
    EXPLORER_URL: 'https://www.mintscan.io/comdex',
    STAKING_URL: 'https://comdex.omniflix.co/stake',
    NETWORK_NAME: 'Comdex',
    NETWORK_TYPE: 'mainnet',
    CHAIN_ID: 'comdex-1',
    CHAIN_NAME: 'Comdex',
    COIN_DENOM: 'CMDX',
    COIN_MINIMAL_DENOM: 'ucmdx',
    COIN_DECIMALS: 6,
    PREFIX: 'comdex',
    COIN_TYPE: 118,
    COSMOSTAION: 'comdex',
    COINGECKO_ID: 'comdex',
    GAS_PRICE_STEP_LOW: 0.025,
    GAS_PRICE_STEP_AVERAGE: 0.03,
    GAS_PRICE_STEP_HIGH: 0.04,
    FEATURES: ['ibc-transfer', 'ibc-go'],
};
